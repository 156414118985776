export interface TranslationData {
  titles: {
    default: string;
    issue: string;
    idea: string;
    other: string;
  };
  categories: {
    issue: string;
    idea: string;
    other: string;
  };
  placeholders: {
    issue: string;
    idea: string;
    other: string;
  };
  buttons: {
    send: string;
    more: string;
  };
  thanks: string;
  footer: string;
  includeScreenshot?: string;
}

export const translations: Record<string, TranslationData> = {
  en: {
    titles: {
      default: `What's on your mind?`,
      idea: `Share an idea`,
      issue: `Report an issue`,
      other: `Tell us anything!`,
    },
    categories: {
      idea: `Idea`,
      issue: `Issue`,
      other: `Other`,
    },
    placeholders: {
      idea: `I would love...`,
      issue: `I noticed that...`,
      other: `What do you want us to know?`,
    },
    buttons: {
      send: `Send feedback`,
      more: `Submit more feedback`,
    },
    thanks: `Thanks! We received your feedback.`,
    footer: `Widget by Feedback Fish`,
    includeScreenshot: 'Capture Screenshot',
  },
  de: {
    titles: {
      default: `Wie kann ich helfen?`,
      idea: `Teile uns eine Idee mit`,
      issue: `Melde ein Problem`,
      other: `Sag uns etwas!`,
    },
    categories: {
      idea: `Idee`,
      issue: `Problem`,
      other: `Sonstiges`,
    },
    placeholders: {
      idea: `Ich wünsche mir...`,
      issue: `Es ist mir aufgefallen...`,
      other: `Was sollen wir wissen?`,
    },
    buttons: {
      send: `Feedback abschicken`,
      more: `Mehr Feedback abgeben`,
    },
    thanks: `Danke sehr! Wir haben dein Feedback erhalten.`,
    footer: `Widget von Feedback Fish`,
    includeScreenshot: 'Screenshot',
  },
  da: {
    titles: {
      default: `Hvad har du i tankerne?`,
      idea: `Del en ide`,
      issue: `Meld en fejl`,
      other: `Fortæl os alt!`,
    },
    categories: {
      idea: `Ide`,
      issue: `Problem`,
      other: `Andet`,
    },
    placeholders: {
      idea: `Jeg ville elske...`,
      issue: `Jeg bemærkede at...`,
      other: `Hvad ønsker du vi skal vide?`,
    },
    buttons: {
      send: `Send feedback`,
      more: `Send mere feedback`,
    },
    thanks: `Tak! Vi har modtaget din feedback`,
    footer: `Widget af Feedback Fish`,
  },
  nl: {
    titles: {
      default: `Waarmee kunnen we je helpen?`,
      idea: `Deel een idee met ons`,
      issue: `Rapporteer een probleem`,
      other: `Vertel ons iets!`,
    },
    categories: {
      idea: `Idee`,
      issue: `Probleem`,
      other: `Anders`,
    },
    placeholders: {
      idea: `Ik zou graag...`,
      issue: `Ik heb opgemerkt dat...`,
      other: `Wat wil je dat we weten?`,
    },
    buttons: {
      send: `Feedback versturen`,
      more: `Meer feedback versturen`,
    },
    thanks: `Bedankt! We hebben jouw feedback ontvangen.`,
    footer: `Widget door Feedback Fish`,
  },
  fr: {
    titles: {
      default: `Comment pouvons-nous vous aider?`,
      idea: `Partagez une idée avec nous`,
      issue: `Signalez un problème`,
      other: `Dites-nous quelque chose!`,
    },
    categories: {
      idea: `Idée`,
      issue: `Problème`,
      other: `Autres`,
    },
    placeholders: {
      idea: `Je voudrais...`,
      issue: `J'ai remarqué...`,
      other: `Que faut-il savoir?`,
    },
    buttons: {
      send: `Envoyez des commentaires`,
      more: `Donnez plus de commentaires`,
    },
    thanks: `Merci beaucoup! Nous avons vos commentaires.`,
    footer: `Widget par Feedback Fish`,
  },
  es: {
    titles: {
      default: `¿Qué tienes en mente?`,
      idea: `Comparte una idea`,
      issue: `Reporta un problema`,
      other: `¡Cuéntanos cualquier cosa!`,
    },
    categories: {
      idea: `Idea`,
      issue: `Problema`,
      other: `Otro`,
    },
    placeholders: {
      idea: `Me encantaría...`,
      issue: `He notado que...`,
      other: `¿Qué quieres que sepamos?`,
    },
    buttons: {
      send: `Enviar opinión`,
      more: `Enviar más opiniones`,
    },
    thanks: `¡Gracias! Hemos recibido tu opinión.`,
    footer: `Widget por Feedback Fish`,
  },
  se: {
    titles: {
      default: `Vad tänker du på?`,
      idea: `Dela en idé`,
      issue: `Rapportera ett problem`,
      other: `Berätta vad som helst!`,
    },
    categories: {
      idea: `Idé`,
      issue: `Problem`,
      other: `Annat`,
    },
    placeholders: {
      idea: `Jag skulle älska...`,
      issue: `Jag märkte att...`,
      other: `Vad vill du att vi ska veta?`,
    },
    buttons: {
      send: `Skicka feedback`,
      more: `Skicka mer feedback`,
    },
    thanks: `Tack! Vi har mottagit din feedback.`,
    footer: `Widget av Feedback Fish`,
  },
  it: {
    titles: {
      default: `A cosa stai pensando?`,
      idea: `Condividi un'idea`,
      issue: `Segnala un problema`,
      other: `Dicci tutto!`,
    },
    categories: {
      idea: `Idea`,
      issue: `Problema`,
      other: `Altro`,
    },
    placeholders: {
      idea: `Vorrei...`,
      issue: `Ho notato che...`,
      other: `Cosa vuoi farci sapere?`,
    },
    buttons: {
      send: `Invia feedback`,
      more: `Invia un altro feedback`,
    },
    thanks: `Grazie! Abbiamo ricevuto il tuo feedback.`,
    footer: `Servizio offerto da Feedback Fish`,
  },
  ar: {
    titles: {
      default: `بماذا تفكر؟`,
      idea: `شارك فكرة`,
      issue: `أخبرنا عن مشكلة`,
      other: `أخبرنا أي شيء!`,
    },
    categories: {
      idea: `فكرة`,
      issue: `مشكلة`,
      other: `أخرى`,
    },
    placeholders: {
      idea: `أحب ذلك...`,
      issue: `لاحظت أن...`,
      other: `ماذا تريدنا أن  نعرف?`,
    },
    buttons: {
      send: ` أرسل رأيك `,
      more: `أرسل آراء أخرى`,
    },
    thanks: `شكرا! لقد تلقينا رسالتك`,
    footer: `التطبيق من Feedback Fish`,
  },
  bg: {
    titles: {
      default: `За какво си мислите?`,
      idea: `Споделете идея`,
      issue: `Докладвайте проблем`,
      other: `Споделете ни нещо!`,
    },
    categories: {
      idea: `Идея`,
      issue: `Проблем`,
      other: `Друго`,
    },
    placeholders: {
      idea: `Бих се радвал...`,
      issue: `Забелязах че...`,
      other: `Какво искате да знаем?`,
    },
    buttons: {
      send: `Изпратете отзиви`,
      more: `Изпратете още отзиви`,
    },
    thanks: `Благодаря! Получихме Вашите отзиви`,
    footer: 'Widget от Feedback Fish',
  },
  he: {
    titles: {
      default: `מה היית רוצה להציע?`,
      idea: `שיתוף ברעיון`,
      issue: `דיווח בעיה`,
      other: `ספרו לנו כל דבר!`,
    },
    categories: {
      idea: `רעיון`,
      issue: `בעיה`,
      other: `אחר`,
    },
    placeholders: {
      idea: `הייתי רוצה לראות...`,
      issue: `שמתי לב ש...`,
      other: `מה היית רוצה שנדע?`,
    },
    buttons: {
      send: `שלח משוב`,
      more: `שלח `,
    },
    thanks: `תודה! קיבלנו את המשוב.`,
    footer: `יישומון בחסות Feedback Fish`,
  },
  pl: {
    titles: {
      default: `Czym chcesz się podzielić?`,
      idea: `Podziel się pomysłem`,
      issue: `Zgłoś problem`,
      other: `Powiedz o czym myślisz!`,
    },
    categories: {
      idea: `Pomysł`,
      issue: `Problem`,
      other: `Inne`,
    },
    placeholders: {
      idea: `Byłoby super, gdyby...`,
      issue: `Zauważyłem...`,
      other: `Co chciałbyś żebyśmy wiedzieli?`,
    },
    buttons: {
      send: `Wyślij feedback`,
      more: `Zgłoś więcej uwag`,
    },
    thanks: `Dziękujemy! Otrzymaliśmy Twój feedback.`,
    footer: `Stworzone przez Feedback Fish`,
  },
  ro: {
    titles: {
      default: `La ce te gândești?`,
      idea: `Trimite o idee`,
      issue: `Raportează o problemă`,
      other: `Spune-ne orice!`,
    },
    categories: {
      idea: `Idee`,
      issue: `Problemă`,
      other: `Altceva`,
    },
    placeholders: {
      idea: `Mi-ar place...`,
      issue: `Am observat că...`,
      other: `Ce dorești să ne transmiți?`,
    },
    buttons: {
      send: `Trimite opinie`,
      more: `Trimite încă o opinie`,
    },
    thanks: `Multumim! Am primit opinia ta.`,
    footer: `Widget creat de Feedback Fish`,
  },
  eu: {
    titles: {
      default: `Zer duzu buruan?`,
      idea: `Zure ideia elkarbanatu`,
      issue: `Arazo baten berri eman`,
      other: `Esan nahi duzuna!`,
    },
    categories: {
      idea: `Ideia`,
      issue: `Arazo`,
      other: `Beste batzuk`,
    },
    placeholders: {
      idea: `Zera gustatuko litzaidake`,
      issue: `Hau ikusi dut lekuz kanpo...`,
      other: `Zer nahiko zenuke guk jakitea?`,
    },
    buttons: {
      send: `Feedback bidali`,
      more: `Bidali feedback gehiago`,
    },
    thanks: `Eskerrik asko! Zure feedback-a jaso dugu.`,
    footer: `Feedback Fish-(e)k egindako widget-a`,
  },
  tr: {
    titles: {
      default: `Ne düşünüyorsun?`,
      idea: `Fikrini paylaş`,
      issue: `Hata bildir`,
      other: `Bize bir şey söyle!`,
    },
    categories: {
      idea: `Fikir`,
      issue: `Hata`,
      other: `Diğer`,
    },
    placeholders: {
      idea: `... hoşlanırım`,
      issue: `... fark ettim`,
      other: `Bizden ne öğrenmek istiyorsunuz?`,
    },
    buttons: {
      send: `Geri bildirim gönder`,
      more: `Daha fazla geri bildirim gönder`,
    },
    thanks: `Teşekkürler. Geri bildirimin bize ulaştı.`,
    footer: `Feedback Fish tarafından tasarlandı`,
  },
  ee: {
    titles: {
      default: `Millest mõtled?`,
      idea: `Jaga ideid`,
      issue: `Teavita meid probleemist`,
      other: `Kirjuta meile millest iganes!`,
    },
    categories: {
      idea: `Idee`,
      issue: `Probleem`,
      other: `Muu`,
    },
    placeholders: {
      idea: `Mulle meeldisks...`,
      issue: `Panin tähele, et...`,
      other: `Kas tahad meile midagi teada anda?`,
    },
    buttons: {
      send: `Saada tagasiside`,
      more: `Kirjuta rohkem`,
    },
    thanks: `Tänan! Saime su tagasiside kätte.`,
    footer: `Vidina ehitas Feedback Fish`,
  },
  uk: {
    titles: {
      default: `Що у вас на думці?`,
      idea: `Поділіться ідеєю`,
      issue: `Повідомте про проблему`,
      other: `Розкажіть нам що завгодно!`,
    },
    categories: {
      idea: `Ідея`,
      issue: `Проблема`,
      other: `Інше`,
    },
    placeholders: {
      idea: `Я хотів би...`,
      issue: `Я помітив, що...`,
      other: `Що ви хочете, щоб ми знали?`,
    },
    buttons: {
      send: `Надіслати відгук`,
      more: `Надіслати більше відгуків`,
    },
    thanks: `Дякую! Ми отримали ваш відгук.`,
    footer: `Віджет від Feedback Fish`,
  },
  pt: {
    titles: {
      default: `O que tens em mente?`,
      idea: `Partilhar uma ideia`,
      issue: `Reportar um problema`,
      other: `Diz-nos qualquer coisa!`,
    },
    categories: {
      idea: `Ideia`,
      issue: `Problema`,
      other: `Outro`,
    },
    placeholders: {
      idea: `Eu gostaria de...`,
      issue: `Eu reparei que...`,
      other: `O que queres que saibamos?`,
    },
    buttons: {
      send: `Enviar comentário`,
      more: `Enviar mais comentários`,
    },
    thanks: `Obrigado! Recebemos o teu comentário.`,
    footer: `Widget por Feedback Fish`,
  },
  pt_BR: {
    titles: {
      default: `O que está em sua mente?`,
      idea: `Compartilhe uma ideia`,
      issue: `Relate um problema`,
      other: `Fale qualquer coisa!`,
    },
    categories: {
      idea: `Ideia`,
      issue: `Problema`,
      other: `Outro`,
    },
    placeholders: {
      idea: `Eu gostaria de...`,
      issue: `Eu notei que...`,
      other: `O que você quer que a gente saiba?`,
    },
    buttons: {
      send: `Enviar feedback`,
      more: `Enviar mais feedback`,
    },
    thanks: `Obrigado! Recebemos seu feedback.`,
    footer: `Widget por Feedback Fish`,
  },
  no: {
    titles: {
      default: `Hva tenker du på?`,
      idea: `Del en idé`,
      issue: `Rapporter et problem`,
      other: `Fortell oss hva som helst!`,
    },
    categories: {
      idea: `Idé`,
      issue: `Problem`,
      other: `Annet`,
    },
    placeholders: {
      idea: `Jeg skulle ønske...`,
      issue: `Jeg la merke til...`,
      other: `Hva vil du vi skal vite?`,
    },
    buttons: {
      send: `Send tilbakemelding`,
      more: `Send flere tilbakemeldinger`,
    },
    thanks: `Takk! Vi har mottatt din tilbakemelding.`,
    footer: `Widget av Feedback Fish`,
  },
  ms: {
    titles: {
      default: `Apakah yang ada di dalam mindamu?`,
      idea: `Kongsikan idea`,
      issue: `laporkan masalah`,
      other: `Beritahu kami apa sahaja!`,
    },
    categories: {
      idea: `Idea`,
      issue: `Isu`,
      other: `Yang lain`,
    },
    placeholders: {
      idea: `Saya suka...`,
      issue: `Saya perhatikan bahawa ...`,
      other: `Apa yang anda mahu kami ketahui?`,
    },
    buttons: {
      send: `Hantar maklumbalas`,
      more: `Hantar lebih banyak maklum balas`,
    },
    thanks: `Terima kasih! Kami menerima maklum balas anda. `,
    footer: `Widget oleh Feedback Fish`,
  },
  cy: {
    titles: {
      default: `Beth sydd ar eich meddwl?`, // literally "What's on your mind?"
      idea: `Rhanwch syniad`,
      issue: `Dargelwch problem`, // literally "Reveal a problem"
      other: `Rhannwch unrhyw sylwadau hefo ni!`,
    },
    categories: {
      idea: `Syniad`,
      issue: `Problem`,
      other: `Rhywbeth arall`,
    },
    placeholders: {
      idea: `Mi fyswn wrth fy modd...`, // literally "I'd be delighted"
      issue: `Mi sylwais fod...`,
      other: `Be ddymunwch ddweud wrthym?`,
    },
    buttons: {
      send: `Danfonwch eich sylwadau`,
      more: `Danfonwch fwy o sylwadau`,
    },
    thanks: `Diolch! Rydym wedi derbyn eich sylwadau.`,
    footer: `Teclyn gan Feedback Fish`,
  },
  kr: {
    titles: {
      default: `무엇을 도와드릴까요?`,
      idea: `아이디어 건의하고 싶어요`,
      issue: `문제점 보고하고 싶어요`,
      other: `그냥 소통하고 싶어요`,
    },
    categories: {
      idea: `아이디어`,
      issue: `문제점`,
      other: `기타`,
    },
    placeholders: {
      idea: `제안하고 싶은 아이디어가 있는데요...`,
      issue: `문제점을 발견했는데요...`,
      other: `어떤 말씀을 하고 싶으세요?`,
    },
    buttons: {
      send: `피드백 보내기`,
      more: `추가 피드백 보내기`,
    },
    thanks: `피드백을 보내주셔서 감사합니다!`,
    footer: `서비스 제공 Feedback Fish`,
  },
  af: {
    titles: {
      default: `Waaraan dink jy?`,
      idea: `Deel 'n idee`,
      issue: `Rapporteer 'n probleem`,
      other: `Vertel ons enigiets!`,
    },
    categories: {
      idea: `Idee`,
      issue: `Probleem`,
      other: `Ander`,
    },
    placeholders: {
      idea: `Ek sal graag ...`,
      issue: `Ek het dit agtergekom...`,
      other: `Wat wil jy hê ons moet weet?`,
    },
    buttons: {
      send: `Stuur terugvoer`,
      more: `Dien meer terugvoer in`,
    },
    thanks: `Dankie! Ons het u terugvoer ontvang.`,
    footer: `Dingus deur Feedback Fish`,
  },
  el: {
    titles: {
      default: `Τι έχετε στο μυαλό σας;`,
      idea: `Μοιραστείτε μια ιδέα`,
      issue: `Αναφέρετε ένα ζήτημα`,
      other: `Πείτε μας ότι θέλετε!`,
    },
    categories: {
      idea: `Ιδέα`,
      issue: `Ζήτημα`,
      other: `Άλλο`,
    },
    placeholders: {
      idea: `Θα ήθελα...`,
      issue: `Παρατήρησα ότι...`,
      other: `Τι θα θέλατε να ξέρουμε;`,
    },
    buttons: {
      send: `Αποστολή κριτικής`,
      more: `Υποβάλετε και άλλη κριτική`,
    },
    thanks: `Ευχαριστούμε! Έχουμε λάβει την κριτική σας.`,
    footer: `Γραφικό στοιχείο από Feedback Fish`,
  },
  ja: {
    titles: {
      default: `どんなことを？`,
      idea: `アイデアを共有する`,
      issue: `問題を報告する`,
      other: `その他の意見`,
    },
    categories: {
      idea: `アイデア`,
      issue: `問題`,
      other: `その他`,
    },
    placeholders: {
      idea: `〇〇がほしいです…`,
      issue: `〇〇という問題を発見しました…`,
      other: `なにをお知らせしますか？`,
    },
    buttons: {
      send: `フィードバックを送る`,
      more: `さらにフィードバックを送る`,
    },
    thanks: `ありがとう！フィードバックを受け付けました`,
    footer: `Widget by Feedback Fish`,
  },
  cat: {
    titles: {
      default: `Quina idea tens al cap?`,
      idea: `Comparteix una idea`,
      issue: `Reporta un problema`,
      other: `Explica'ns alguna cosa!`,
    },
    categories: {
      idea: `Idea`,
      issue: `Problema`,
      other: `Altres`,
    },
    placeholders: {
      idea: `M'agradaria...`,
      issue: `M'he adonat que...`,
      other: `Què ens vols explicar?`,
    },
    buttons: {
      send: `Enviar opinió`,
      more: `Enviar més opinions`,
    },
    thanks: `Gràcies! Hem rebut la teva opinió.`,
    footer: `Giny fet per Feedback Fish`,
  },
  fi: {
    titles: {
      default: `Mitä mielessä?`,
      idea: `Jaa idea`,
      issue: `Raportoi ongelma`,
      other: `Kerro meille mitä tahansa!`,
    },
    categories: {
      idea: `Idea`,
      issue: `Ongelma`,
      other: `Jotain muuta`,
    },
    placeholders: {
      idea: `Haluaisin...`,
      issue: `Huomasin, että...`,
      other: `Mitä haluat meidän tietävän?`,
    },
    buttons: {
      send: `Lähetä palaute`,
      more: `Lisää palautetta`,
    },
    thanks: `Kiitos! Olemme vastaanottaneet palautteesi`,
    footer: `Widgetin tarjoaa Feedback Fish`,
  },
  or: {
    titles: {
      default: `ଆପଣଙ୍କ ମନରେ କଣ ଅଛି?`,
      idea: `ଏକ ଧାରଣା ଅଂଶୀଦାର କରନ୍ତୁ `,
      issue: `ଏକ ସମସ୍ୟା ରିପୋର୍ଟ କରନ୍ତୁ `,
      other: `ଆମକୁ କିଛି ବି ଜଣାନ୍ତୁ!`,
    },
    categories: {
      idea: `ଧାରଣା`,
      issue: `ସମସ୍ୟା`,
      other: `ଅନ୍ୟ`,
    },
    placeholders: {
      idea: `ମୋତେ ଭଲ ଲାଗିବ...`,
      issue: `ମୁଁ ଲକ୍ଷ୍ୟ କଲି ଯେ...`,
      other: `ଆପଣ ଆମକୁ କ’ଣ ଜଣେଇବାକୁ ଚାହାଁନ୍ତି?`,
    },
    buttons: {
      send: `ମତାମତ ପଠାନ୍ତୁ`,
      more: `ଅଧିକ ମତାମତ ପଠାନ୍ତୁ`,
    },
    thanks: `ଧନ୍ୟବାଦ! ଆମେ ଆପଣଙ୍କର ମତାମତ ଗ୍ରହଣ କରିଛୁ |`,
    footer: `ୱିଜେଟ୍ Feedback Fishଙ୍କ ଦ୍ୱାରା`,
  },
  cz: {
    titles: {
      default: `Co máte na mysli?`,
      idea: `Sdílet nápad`,
      issue: `Nahlásit chybu`,
      other: `Řekněte nám cokoliv!`,
    },
    categories: {
      idea: `Nápad`,
      issue: `Chyba`,
      other: `Ostatní`,
    },
    placeholders: {
      idea: `Rád bych...`,
      issue: `Všiml jsem si, že...`,
      other: `Co bychom měli vědět?`,
    },
    buttons: {
      send: `Poslat zpětnout vazbu`,
      more: `Poskytnout více zpětné vazby`,
    },
    thanks: `Děkujeme! Vaši zpětnou vazbu jsme obdrželi.`,
    footer: `Widget od Feedback Fish`,
  },
  fa: {
    titles: {
      default: `چه چیزی تو ذهنته؟`,
      idea: `ایده جدید‌`,
      issue: `گزارش خطا`,
      other: `هر چیز دیگه‌ای!`,
    },
    categories: {
      idea: `ایده جدید`,
      issue: `خطا یا مشکل`,
      other: `چیز دیگه‌ای`,
    },
    placeholders: {
      idea: `خیلی دوست داشتم اگه...`,
      issue: `من متوجه شدم که...`,
      other: `چه چیز دیگه‌ای رو میخوای به ما بگی؟`,
    },
    buttons: {
      send: `ارسال`,
      more: `ارسال بازخوردهای بیشتر`,
    },
    thanks: `خیلی ممنون! بازخورد شما رو دریافت کردیم..`,
    footer: `قدرت گرفته از Feedback Fish`,
  },
  hi: {
    titles: {
      default: `आपके दिमाग में क्या है?`,
      idea: `हमारे साथ एक विचार साझा करें`,
      issue: `समस्या के बारे में बताएं`,
      other: `हमें कुछ भी बताइये!`,
    },
    categories: {
      idea: `विचार`,
      issue: `समस्या`,
      other: `अन्य`,
    },
    placeholders: {
      idea: `मुझे अच्छा लगेगा...`,
      issue: `मैंने गौर किया...`,
      other: `हमें क्या पता होना चाहिए?`,
    },
    buttons: {
      send: `प्रतिक्रिया भेजें`,
      more: `अधिक प्रतिक्रिया प्रदान करें`,
    },
    thanks: `धन्यवाद! हमें आपकी प्रतिक्रिया मिली।.`,
    footer: `Feedback Fishके द्वारा विजेट`,
  },
  vi: {
    titles: {
      default: `Bạn đang nghĩ gì?`,
      idea: `Chia sẻ một ý tưởng`,
      issue: `Báo cáo sự cố`,
      other: `Hãy cho chúng tôi biết bất cứ điều gì!`,
    },
    categories: {
      idea: `Ý tưởng`,
      issue: `Sự cố`,
      other: `Khác`,
    },
    placeholders: {
      idea: `Tôi rất thích...`,
      issue: `Tôi nhận thấy rằng...`,
      other: `Bạn muốn chúng tôi biết điều gì?`,
    },
    buttons: {
      send: `Gởi phản hồi`,
      more: `Gởi thêm phản hồi`,
    },
    thanks: `Cảm ơn! Chúng tôi đã nhận được phản hồi của bạn.`,
    footer: `Widget bởi Feedback Fish`,
  },
  ru: {
    titles: {
      default: `Хотите поделиться?`,
      idea: `Поделись идеей`,
      issue: `Сообщи о проблеме`,
      other: `Расскажи что угодно!`,
    },
    categories: {
      idea: `Идея`,
      issue: `Проблема`,
      other: `Другое`,
    },
    placeholders: {
      idea: `Было бы здорово, если...`,
      issue: `Мне кажется, что...`,
      other: `О чём ты хочешь нам рассказать?`,
    },
    buttons: {
      send: `Отправить отзыв`,
      more: `Отправить ещё отзыв`,
    },
    thanks: `Спасибо! Мы получили твой отзыв.`,
    footer: `Виджет Feedback Fish`,
  },
  bs: {
    titles: {
      default: `Šta ti je na umu?`,
      idea: `Podijeli ideju`,
      issue: `Prijavi grešku`,
      other: `Reci nam nešto!`,
    },
    categories: {
      idea: `Ideja`,
      issue: `Greška`,
      other: `Drugo`,
    },
    placeholders: {
      idea: `Volio/la bih...`,
      issue: `Primjetio/la sam...`,
      other: `Šta želite da znamo?`,
    },
    buttons: {
      send: `Pošalji povratnu informaciju`,
      more: `Predaj još povratnih informacija`,
    },
    thanks: `Hvala! Primili smo vaše povratne informacije.`,
    footer: `Widget od Feedback Fish`,
  },
  ig: {
    titles: {
      default: `Kedu ihe dị n'uche gị?`,
      idea: `Gwa anyi ihe I chere`,
      issue: `Kpesa mmetọ`,
      other: `Gwa anyị ihe ọ bụla!`,
    },
    categories: {
      idea: `Echiche`,
      issue: `Nsogbu`,
      other: `Ndị ọzọ`,
    },
    placeholders: {
      idea: `Ọ ga-amasị m...`,
      issue: `Achọpụtara m na ...`,
      other: `Kedu ihe ị chọrọ ka anyị mara?`,
    },
    buttons: {
      send: `Zite ozi nzaghachi`,
      more: `Nyefee ozi nzaghachi ọzọ`,
    },
    thanks: `Daalụ!  Anyị natara ozi nzaghachi gị.`,
    footer: `Wijetị site na Feedback Fish`,
  },
  hu: {
    titles: {
      default: `Mi jár a fejedben?`,
      idea: `Oszd meg az ötleted`,
      issue: `Hiba jelentése`,
      other: `Mesélj nekünk bármit!`,
    },
    categories: {
      idea: `Ötlet`,
      issue: `Probléma`,
      other: `Egyéb`,
    },
    placeholders: {
      idea: `Szeretném, ha...`,
      issue: `Azt vettem észre, hogy...`,
      other: `Miről szeretnéd, ha tudnánk?`,
    },
    buttons: {
      send: `Visszajelzés elküldése`,
      more: `További visszajelzés küldése`,
    },
    thanks: `Köszönjük! Megkaptuk a visszajelzésed.`,
    footer: `Készítette a Feedback Fish`,
  },
  zh_CN: {
    titles: {
      default: `您有什么想法?`,
      idea: `分享想法`,
      issue: `反映问题`,
      other: `请告诉我们任何事情！`,
    },
    categories: {
      idea: `想法`,
      issue: `问题`,
      other: `其它`,
    },
    placeholders: {
      idea: `我希望...`,
      issue: `我发现...`,
      other: `您想让我们知道什么？`,
    },
    buttons: {
      send: `提交反馈`,
      more: `提交更多反馈`,
    },
    thanks: `谢谢！我们收到了您的反馈。`,
    footer: `Widget 来自 Feedback Fish`,
  },
  zh_TW: {
    titles: {
      default: `您有什麼想法?`,
      idea: `分享想法`,
      issue: `反映問題`,
      other: `請告訴我們任何事情！`,
    },
    categories: {
      idea: `想法`,
      issue: `問題`,
      other: `其它`,
    },
    placeholders: {
      idea: `我希望...`,
      issue: `我發現...`,
      other: `您想讓我們知道什麼？`,
    },
    buttons: {
      send: `提交反饋`,
      more: `提交更多反饋`,
    },
    thanks: `謝謝！我們收到了您的反饋。`,
    footer: `Widget 來自 Feedback Fish`,
  },
  ur: {
    titles: {
      default: `آپ کے زہن میں کیا ہے`,
      idea: `کوئ بات کرے `,
      issue: ` کسی مسئلہ کی اطلاع دیں`,
      other: `!کچھ بھی بتاے`,
    },
    categories: {
      idea: `خیال`,
      issue: `مسئلہ`,
      other: `کچھ اور`,
    },
    placeholders: {
      idea: `...مجھے اچھا لگے گا`,
      issue: ` ...میں نے دیکھاکہ`,
      other: `آپ ہمیں کچھ بتانا چاہتے ہیں؟  `,
    },
    buttons: {
      send: `رائے بھیجیں`,
      more: `مزید تاثرات پیش کریں`,
    },
    thanks: `شکریہ! ہمیں آپ کی رائے موصول ہوئی ہے۔`,
    footer: `ویجیٹ بذریعہ Feedback Fish`,
  },
  fil: {
    titles: {
      default: `Ano ang nasa isip mo?`,
      idea: `Magbahagi ng ideya`,
      issue: `Mag-ulat ng isyu`,
      other: `Sabihin mo sa amin kahit ano!`,
    },
    categories: {
      idea: `Ideya`,
      issue: `Isyu`,
      other: `Iba`,
    },
    placeholders: {
      idea: `Gusto ko sana...`,
      issue: `Napansin ko na...`,
      other: `Ano ang gusto mong malaman namin?`,
    },
    buttons: {
      send: `Magpadala ng tugon`,
      more: `Magpadala pa ng tugon`,
    },
    thanks: `Salamat! Natanggap na namin ang inyong tugon.`,
    footer: `Widget ni Feedback Fish`,
  },
  sk: {
    titles: {
      default: `Na čo myslíte?`,
      idea: `Zdielať nápad`,
      issue: `Nahlásiť chybu`,
      other: `Povedz nám čokolvek!`,
    },
    categories: {
      idea: `Nápad`,
      issue: `Chyba`,
      other: `Iné`,
    },
    placeholders: {
      idea: `Rád by som...`,
      issue: `Všimol som si, že...`,
      other: `Čo by sme mali vedieť?`,
    },
    buttons: {
      send: `Odoslať spätnú väzbu`,
      more: `Získať viac spätnej väzby`,
    },
    thanks: `Ďakujeme! Získali sme vašu spätnú väzbu.`,
    footer: `Widget od Feedback Fish`,
  },
  br: {
    titles: {
      default: `E petra emaoc'h o soñjal?`,
      idea: `Rannit ur mennozh ganimp`,
      issue: `Danevelliñ ur gudenn`,
      other: `Lârit deomp un dra bennak!`,
    },
    categories: {
      idea: `Mennozh`,
      issue: `Kudenn`,
      other: `All`,
    },
    placeholders: {
      idea: `C'hoant em befe...`,
      issue: ` Merzet em eus...`,
      other: `Petra eo ret deomp gouzout?`,
    },
    buttons: {
      send: `Kasit evezhiadennoù`,
      more: `Kasit muioc'h a evezhiadennoù`,
    },
    thanks: `Mersi! Resevet hon eus hoc'h evezhiadennoù.`,
    footer: `Widjet gant Feedback Fish`,
  },
  is: {
    titles: {
      default: `Hvað ertu að spá?`,
      idea: `Deila hugmynd`,
      issue: `Tilkynna vandamál`,
      other: `Segðu okkur frá hverju sem er!`,
    },
    categories: {
      idea: `Hugmynd`,
      issue: `Vandamál`,
      other: `Annað`,
    },
    placeholders: {
      idea: `Mér þætti frábært ef...`,
      issue: `Ég tók eftir að...`,
      other: `Hvað viltu segja okkur?`,
    },
    buttons: {
      send: `Senda ábendingu`,
      more: `Senda fleiri ábendingar`,
    },
    thanks: `Takk! Við höfum móttekið ábendinguna.`,
    footer: `Þjónusta eftir`,
  }
};
