function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const getScreenshot = async (): Promise<string | undefined> => {
  if (inIframe()) {
    let listener: (e: MessageEvent) => void;
    return new Promise<string>((res, rej) => {
      listener = (e: MessageEvent) => {
        try {
          const data = JSON.parse(e.data);
          if (data.type === 'screenshot') {
            res(data.data as string);
          }
        } catch (e) {
          console.log(e);
          rej();
        }
      };

      window.addEventListener('message', listener);
      window.parent.postMessage(JSON.stringify({ type: 'screenshot' }), '*');

      setTimeout(() => rej('no_response'), 5000);
    }).finally(() => {
      if (listener) {
        window.removeEventListener('message', listener);
      }
    });
  } else {
    try {
      const screen = await (await import('@gripeless/pico')).dataURL(window, {
        ignore: ['#feedback-fish'],
      });
      return screen.value;
    } catch (err) {
      return undefined;
    }
  }
};

export const uploadScreenshot = async (base64: string) => {
  const id = await fetch('https://image.feedback.fish/upload', {
    method: 'POST',
    body: base64,
  }).then(r => r.text());

  return `https://image.feedback.fish/img/${id}`;
};
