/* @jsx jsx */
import { jsx } from 'theme-ui';
import { fadeInAnimation } from './animations';

import { Spinner } from 'theme-ui';

export const Loader = () => (
  <div
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      bg: 'gray.2',
      justifyContent: 'center',
      alignItems: 'center',
      animation: `${fadeInAnimation} 0.1s ease-out`,
    }}
  >
    <Spinner size={18} color="blue.5" />
  </div>
);
