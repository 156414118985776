/* @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Flex as Reflex, BoxProps } from 'reflexbox';

interface FlexProps extends BoxProps {
  gap?: number;
  children: React.ReactNode;
  id?: string;
}

export const Flex: React.FC<FlexProps> = React.forwardRef((props, ref) => {
  let { children, ...rest } = props;

  if (typeof props.gap === 'number' && props.gap !== 0) {
    children = React.Children.map(children, (child, index) => {
      if (!child) return child;

      return jsx(
        'div',
        {
          ref,
          sx:
            index !== 0
              ? {
                  [props.flexDirection === 'column'
                    ? 'marginTop'
                    : 'marginLeft']: props.gap,
                }
              : {},
        },
        child
      );
    });
  }

  // @ts-ignore
  return jsx(Reflex, { ...rest, ref }, children);
});
