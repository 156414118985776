/* @jsx jsx */
import { jsx, ThemeProvider } from 'theme-ui';
import React, { useRef } from 'react';
import {
  useState,
  FormEvent,
  useEffect,
  useMemo,
  Fragment,
  CSSProperties,
} from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';
import theme from '../../app/src/gatsby-plugin-theme-ui';
import { Flex } from './Flex';
import { useWindowSize } from './hooks';
import { getScreenshot, uploadScreenshot } from './utils';
import {
  fadeInAnimation,
  checkmarkAnimation,
  scaleUpAnimation,
  enterUpAnimation,
} from './animations';
import {
  IconClose,
  IconBack,
  IconWarning,
  IconBulb,
  IconDots,
  IconScreenshot,
} from './svg/icons';
import { translations } from './translations';
import { Loader } from './Loader';

const titleIcon = {
  idea: () => <IconBulb sx={{ width: 6, height: 5 }} />,
  issue: () => <IconWarning sx={{ width: 6, height: 5 }} />,
  other: () => ``,
};

const EmojiButton = (props: {
  onClick: () => void;
  selected: boolean;
  icon: JSX.Element;
  label: string;
  children: string;
}) => (
  <button
    aria-label={props.label}
    sx={{
      display: `flex`,
      width: '100%',
      cursor: 'pointer',
      flexDirection: `column`,
      alignItems: `center`,
      backgroundColor: `gray.1`,
      border: 'none',
      opacity: 1,
      borderRadius: 10,
      color: 'gray.7',
      justifyContent: 'center',
      height: '100%',
      transition: 'background 100ms ease-in-out',
      ':hover': {
        backgroundColor: `gray.2`,
      },
      ':active': {
        backgroundColor: `gray.3`,
      },
    }}
    onClick={evt => {
      evt.preventDefault();
      props.onClick();
    }}
  >
    {props.icon}
    <span
      sx={{
        fontSize: '0.875rem',
        fontWeight: `600`,
        marginTop: '8px',
        lineHeight: 1,
      }}
    >
      {props.children}
    </span>
  </button>
);

const IconButton: React.FC<{
  onClick?: (e: React.MouseEvent) => void;
  label: string;
  className?: string;
}> = ({ children, onClick, className, label }) => (
  <button
    aria-label={label}
    onClick={onClick}
    className={className}
    sx={{
      p: 0,
      border: 'none',
      bg: 'transparent',
      borderRadius: 4,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'gray.5',
      width: 6,
      height: 6,
      cursor: 'pointer',
      transition: 'color ease-in-out 100ms',
      ':hover': {
        color: 'gray.6',
      },
    }}
  >
    {children}
  </button>
);

const Checkmark = () => (
  <div
    sx={{
      width: '48px',
      opacity: 0,
      height: '48px',
      borderRadius: '24px',
      backgroundColor: '#22DB69',
      position: 'relative',
      transform: 'rotate(45deg)',
      animation: `${scaleUpAnimation} 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
      animationDelay: '0.3s',
      '&:after': {
        content: '""',
        visibility: 'hidden',
        animation: `${checkmarkAnimation} 0.15s ease-out forwards`,
        animationDelay: '0.45s',
        position: 'absolute',
        borderRight: '4px solid',
        borderBottom: '4px solid',
        borderColor: 'white',
        bottom: '15px',
        left: '17px',
        height: '20px',
        width: '11px',
      },
    }}
  />
);

interface FormProps {
  whitelabel?: boolean;
  projectId?: string;
  postUrl?: string;
  element?: HTMLElement | null;
  triggerComponent?: React.ComponentType<{
    onClick: (evt: React.MouseEvent<HTMLElement>) => void;
  }>;
  userId?: string;
  metadata?: {
    [key: string]: string;
  };
  onClose?: () => void;
  lang?: string;
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function Form(props: FormProps) {
  const {
    projectId,
    postUrl = process.env.FF_INTERNAL_DEV
      ? `http://localhost:4321/feedback`
      : `https://api.feedback.fish/feedback`,
    userId,
    metadata,
    onClose,
  } = props;
  const [loading, setLoading] = useState(false);
  const [screenshotLoading, setScreenshotLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [feedbackText, setFeedback] = useState('');
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [screenshotUrl, setScreenshotUrl] = useState<string | null>(null);

  const [showTooltip, setShowTooltip] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 5,
        },
      },
    ],
  });
  const [category, setCategory] = useState<'issue' | 'idea' | 'other' | null>(
    null
  );
  const language =
    props.lang ||
    (typeof document !== `undefined` && document.querySelector('html')?.lang) ||
    'en';
  const texts = translations[language] || translations[`en`];
  const enabledSubmit = feedbackText.length > 0;

  const [whitelabel] = useState<boolean>(!!props.whitelabel);
  // useEffect(() => {
  //   fetch(`${postUrl.replace(`/feedback`, '')}/project-plan?pid=${projectId}`)
  //     .then(res => res.text())
  //     .then(plan => {
  //       setWhitelabel(plan === 'enterprise');
  //     })
  //     // Ignore errors
  //     .catch(() => {});
  // }, []);

  useEffect(() => {
    if (!submitted || !onClose) return;

    const timeout = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timeout);
  }, [submitted]);

  return (
    <ThemeProvider
      theme={{
        ...theme,
        styles: {},
        useBodyStyles: false,
        useLocalStorage: false,
      }}
    >
      <Flex
        id="feedback-fish"
        as="form"
        // @ts-ignore
        action={postUrl}
        ref={formRef}
        method="POST"
        onSubmit={async (evt: FormEvent<HTMLFormElement>) => {
          evt.preventDefault();
          if (!enabledSubmit || loading || !projectId) return;

          setLoading(true);
          const formData = new FormData(evt.currentTarget);

          const data = new URLSearchParams();
          formData.forEach(function(value, key) {
            if (typeof value !== `string`) return;
            data.append(key, value);
          });

          if (userId) {
            data.append('userId', userId);
          }

          if (metadata) {
            data.append('metadata', JSON.stringify(metadata));
          }

          if (inIframe()) {
            data.append('location', document.referrer);
          }

          await fetch(postUrl, {
            method: 'POST',
            body: data,
            referrerPolicy: 'unsafe-url',
          })
            .catch(() => {
              setLoading(false);
            })
            .then(() => {
              setSubmitted(true);
              setLoading(false);
            });
        }}
        flexDirection="column"
        sx={{
          minHeight: '200px',
          borderRadius: 16,
          minWidth: '320px',
          background: `white`,
          px: 3,
          overflow: 'hidden',
          position: 'relative',
          boxShadow: '0 18px 50px -10px rgba(0,0,0,.2)',

          'input, button, textarea': {
            outline: 'none',
            fontFamily: 'inherit',
            transition: '100ms ease-in-out',
            transitionProperty: 'color, box-shadow, background, border-color',
            boxShadow: '0 0 0 0 rgba(0, 93, 255, 0)',
          },
          '*:focus': {
            outline: 'none',
            boxShadow: '0 0 0 3px rgba(0, 93, 255, 0.6)',
          },
        }}
      >
        {category && !submitted && (
          <IconButton
            label="Back"
            sx={{
              position: 'absolute',
              left: '16px',
              top: '16px',
            }}
            onClick={() => {
              setCategory(null);
              setFeedback('');
            }}
          >
            <IconBack />
          </IconButton>
        )}

        <IconButton
          label="Close"
          onClick={onClose}
          sx={{ position: 'absolute', right: '16px', top: '16px', zIndex: 1 }}
        >
          <IconClose />
        </IconButton>
        <div
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            p: '15px 16px',
          }}
        >
          <div
            sx={{
              mr: '8px',
              height: '26px',
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
            }}
          >
            {category ? titleIcon[category]() : ''}
          </div>
          <h1
            sx={{
              fontSize: '1.25rem',
              textAlign: `center`,
              display: 'inline',
              fontWeight: 'bold',
              color: 'gray.9',
              margin: 0,
              lineHeight: 1,
            }}
          >
            {texts.titles[category || 'default']}
          </h1>
        </div>
        {!category ? (
          <Flex
            justifyContent="space-between"
            gap={2}
            sx={{
              width: `100%`,
              flex: 1,
              alignItems: 'stretch',
              '> *': { flex: 1 },
            }}
          >
            <EmojiButton
              icon={<IconWarning />}
              label="Issue"
              selected={category === `issue`}
              onClick={() => setCategory(`issue`)}
            >
              {texts.categories[`issue`]}
            </EmojiButton>
            <EmojiButton
              icon={<IconBulb />}
              label="Idea"
              selected={category === `idea`}
              onClick={() => setCategory(`idea`)}
            >
              {texts.categories[`idea`]}
            </EmojiButton>
            <EmojiButton
              icon={<IconDots />}
              label="Other"
              selected={category === `other`}
              onClick={() => setCategory(`other`)}
            >
              {texts.categories[`other`]}
            </EmojiButton>
          </Flex>
        ) : !submitted ? (
          <React.Fragment>
            <textarea
              name="text"
              id="text"
              onKeyDown={e => {
                if (!(e.keyCode == 13 && e.metaKey)) return;
                formRef.current?.dispatchEvent(new Event('submit'));
              }}
              autoFocus={true}
              onChange={e => setFeedback(e.target.value)}
              sx={{
                border: 'solid',
                borderWidth: '2px',
                borderColor: 'gray.3',
                borderRadius: 8,
                p: '8px',
                flex: 1,
                fontFamily: 'inherit',
                fontWeight: 500,
                wordBreak: 'break-word',
                fontSize: '0.875rem',
                outline: 'none',
                color: 'gray.8',
                resize: 'none',
                appearance: 'none',
                '::-webkit-appearance': { appearance: 'none' },
                '::placeholder': { color: 'gray.5' },
                ':focus': {
                  borderColor: 'brandBlue.2',
                },
              }}
              placeholder={texts.placeholders[category]}
            />

            <div sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <div sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                {showTooltip && (
                  <div
                    ref={setPopperElement}
                    style={styles.popper}
                    sx={{
                      backgroundColor: 'gray.7',
                      color: 'white',
                      borderRadius: 7,
                      px: 2,
                      py: 1,
                      fontSize: 1,
                    }}
                    {...attributes.popper}
                  >
                    {texts.includeScreenshot || 'Capture Screenshot'}
                    <div
                      ref={setArrowElement}
                      style={styles.arrow}
                      sx={{ width: 2, height: 2, bottom: '-3px' }}
                    >
                      <div
                        sx={{
                          width: 2,
                          height: 2,
                          backgroundColor: 'gray.7',
                          transform: 'rotate(-45deg)',
                          borderRadius: '2px',
                        }}
                      />
                    </div>
                  </div>
                )}
                {category !== 'idea' && !screenshot && (
                  <button
                    type="button"
                    ref={setReferenceElement}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    sx={{
                      p: '7px',
                      height: '30px',
                      backgroundColor: 'gray.2',
                      borderRadius: 7,
                      marginRight: 1,
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                    onClick={async e => {
                      e.preventDefault();
                      setScreenshotLoading(true);
                      try {
                        const res = await getScreenshot();
                        if (res) {
                          const url = await uploadScreenshot(res);
                          setScreenshotUrl(url);
                          setScreenshot(res);
                          setScreenshotLoading(false);
                        }
                      } catch (err) {
                        setScreenshotLoading(false);
                      }
                      setShowTooltip(false);
                    }}
                  >
                    <IconScreenshot
                      style={{ transform: 'translate(2px, -2px)' }}
                    />
                    {screenshotLoading && <Loader />}
                  </button>
                )}
              </div>
              {screenshot && (
                <React.Fragment>
                  <div
                    sx={{
                      position: 'relative',
                      height: '30px',
                      width: '34px',
                      marginRight: 1,
                    }}
                  >
                    <a
                      href={screenshotUrl!}
                      target="_blank"
                      sx={{
                        p: 0,
                        height: '30px',
                        width: '34px',
                        backgroundColor: 'gray.2',
                        borderRadius: 7,
                        marginRight: 1,
                        position: 'relative',
                        display: 'block',
                        boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.1)',
                        backgroundImage: `url(${screenshot})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                        ':after': {
                          background:
                            'linear-gradient(0deg,rgba(0,0,0,0.15) 0%,rgba(255,255,255,0) 100%)',
                          content: '" "',
                          position: 'absolute',
                          left: 0,
                          bottom: 0,
                          right: 0,
                          top: 0,
                          borderRadius: 7,
                        },
                      }}
                    />
                    <button
                      style={{ top: -3, right: -3 }}
                      sx={{
                        position: 'absolute',
                        borderRadius: 10,
                        width: 3,
                        height: 3,
                        backgroundColor: 'red.6',
                        display: 'flex',
                        color: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ':hover': {
                          backgroundColor: 'red.4',
                        },
                      }}
                      onClick={e => {
                        e.preventDefault();
                        setScreenshot(null);
                      }}
                    >
                      <IconClose width={6} height={6} />
                    </button>
                  </div>
                  <input
                    name="screenshotUrl"
                    type="hidden"
                    value={screenshotUrl || undefined}
                  />
                </React.Fragment>
              )}

              <button
                disabled={!enabledSubmit || loading}
                sx={{
                  alignSelf: `flex-end`,
                  border: `none`,
                  width: '100%',
                  backgroundColor: enabledSubmit ? `brandBlue.2` : 'gray.2',
                  borderRadius: 7,
                  color: enabledSubmit ? `white` : 'gray.5',
                  fontSize: 0,
                  py: '4px',
                  fontFamily: 'inherit',
                  px: '10px',
                  transition: 'all',
                  overflow: 'hidden',
                  transitionDuration: '500ms',
                  fontWeight: '500',
                  cursor: enabledSubmit ? 'pointer' : 'initial',
                  position: 'relative',
                }}
                type="submit"
              >
                <div
                  sx={{
                    opacity: loading ? 0 : 1,
                    fontWeight: '600',
                    fontSize: '0.875rem',
                  }}
                >
                  {texts.buttons.send}
                </div>
                {loading && <Loader />}
              </button>
            </div>
          </React.Fragment>
        ) : (
          <div
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              background: 'white',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              color: 'gray.8',
              justifyContent: 'center',
              animation: `${fadeInAnimation} 0.2s ease-out`,
            }}
          >
            <Checkmark />
            <div
              sx={{
                fontWeight: '600',
                mt: '12px',
                opacity: 0,
                animation: `${enterUpAnimation} 0.6s 0.1s cubic-bezier(0.16, 1, 0.3, 1) forwards`,
              }}
            >
              {texts.thanks}
            </div>
            <button
              onClick={() => {
                setCategory(null);
                setFeedback('');
                setSubmitted(false);
              }}
              sx={{
                border: 'none',
                background: 'none',
                mt: '12px',
                fontSize: 1,
                px: '12px',
                opacity: 0,
                animation: `${enterUpAnimation} 0.6s 0.15s cubic-bezier(0.16, 1, 0.3, 1) forwards`,

                color: 'gray.7',
                bg: 'gray.2',
                py: 0,
                borderRadius: 16,
                cursor: 'pointer',
                ':hover': {
                  color: 'gray.8',
                  bg: 'gray.3',
                },
              }}
            >
              {texts.buttons.more}
            </button>
          </div>
        )}

        <input
          type="hidden"
          id="projectId"
          name="projectId"
          value={projectId}
        />
        <input
          type="hidden"
          id="category"
          name="category"
          value={category || ''}
        />
        <div
          sx={{
            fontSize: '10px',
            display: 'flex',
            paddingY: '8px',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'gray.5',
          }}
        >
          {whitelabel !== true && (
            <React.Fragment>
              <svg
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                sx={{ mr: 1, mt: '1px' }}
              >
                <circle
                  r="5.04935"
                  transform="matrix(-1 0 0 1 10.9394 5.04935)"
                  fill="#A0AEC0"
                />
                <path
                  d="M10.9394 10.0987C8.15075 10.0987 5.89008 7.85999 5.89008 5.09839H15.9888C15.9888 7.85999 13.7281 10.0987 10.9394 10.0987Z"
                  fill="#2D3748"
                />
                <path
                  d="M5.88987 5.04935C5.88987 7.83802 3.60726 10.0987 0.791504 10.0987L0.791504 0C3.60726 -1.2308e-07 5.88987 2.26067 5.88987 5.04935Z"
                  fill="#CBD5E0"
                />
              </svg>
              {texts.footer.substr(0, texts.footer.indexOf(`Feedback Fish`))}
              <a
                sx={{ color: 'gray.6', ml: 1, textDecoration: 'none' }}
                target="_blank"
                href={`https://feedback.fish?ref=widget&pid=${projectId}`}
              >
                Feedback Fish
              </a>
              {texts.footer.substr(
                texts.footer.indexOf(`Feedback Fish`) + `Feedback Fish`.length
              )}
            </React.Fragment>
          )}
        </div>
      </Flex>
    </ThemeProvider>
  );
}

interface PortalProps {
  children: React.ReactNode;
  element: HTMLElement;
}

function Portal({ element, children }: PortalProps) {
  const [widgetRef, setWidgetRef] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(element, widgetRef, {
    placement: 'bottom',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      {
        name: 'flip',
        options: {
          padding: 10,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  });
  const size = useWindowSize();

  const portalEl = useMemo(() => {
    const span = document.createElement('span');
    document.body.appendChild(span);

    return span;
  }, []);

  useEffect(() => {
    return () => {
      document.body.removeChild(portalEl);
    };
  }, []);

  const style = useMemo(() => {
    const s =
      (size?.width ?? 0) < 500
        ? ({
            position: 'fixed',
            top: 5,
            bottom: 5,
            left: 5,
            right: 5,
            zIndex: 99999,
          } as CSSProperties)
        : { ...styles.popper, zIndex: 99999 };

    return s;
  }, [(size?.width ?? 0) < 500, styles.popper]);

  return ReactDOM.createPortal(
    <div ref={t => setWidgetRef(t)} style={style} {...attributes}>
      {children}
    </div>,
    portalEl
  );
}

export function FeedbackForm(props: FormProps) {
  const [referenceEl, setReferenceEl] = useState<HTMLElement | null>(null);

  if (!props.element && !props.triggerComponent) return <Form {...props} />;

  if (props.element) {
    return (
      <Portal element={props.element}>
        <Form {...props} />
      </Portal>
    );
  }

  if (!props.triggerComponent) return null;

  const Trigger = props.triggerComponent;
  return (
    <Fragment>
      <Trigger
        onClick={evt => {
          evt.preventDefault();
          setReferenceEl(referenceEl ? null : evt.currentTarget);
        }}
      />
      {referenceEl && (
        <Portal element={referenceEl}>
          <OutsideClickHandler
            onOutsideClick={el => {
              if (el.target !== referenceEl) {
                setReferenceEl(null);
              }
            }}
          >
            <Form {...props} onClose={() => setReferenceEl(null)} />
          </OutsideClickHandler>
        </Portal>
      )}
    </Fragment>
  );
}
