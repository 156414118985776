/* @jsx jsx */
import { jsx } from 'theme-ui';

export const IconClose = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      fill="currentColor"
      viewBox="0 0 11 11"
      {...props}
    >
      <path d="M10.707 1.707A1 1 0 009.293.293l1.414 1.414zm-1.414 9a1 1 0 001.414-1.414l-1.414 1.414zM1.707.293A1 1 0 00.293 1.707L1.707.293zm-1.414 9a1 1 0 101.414 1.414L.293 9.293zm5.914-3.086l4.5-4.5L9.293.293l-4.5 4.5 1.414 1.414zm-1.414 0l4.5 4.5 1.414-1.414-4.5-4.5-1.414 1.414zm1.414-1.414l-4.5-4.5L.293 1.707l4.5 4.5 1.414-1.414zm-1.414 0l-4.5 4.5 1.414 1.414 4.5-4.5-1.414-1.414z"></path>
    </svg>
  );
};

export const IconBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="11"
      fill="currentColor"
      viewBox="0 0 13 11"
    >
      <path d="M1 5.5l-.747-.664a1 1 0 000 1.328L1 5.5zm11 1a1 1 0 100-2v2zM5.747 1.664A1 1 0 104.253.336l1.494 1.328zm-1.494 9a1 1 0 001.494-1.328l-1.494 1.328zM1 6.5h11v-2H1v2zm.747-.336l4-4.5L4.253.336l-4 4.5 1.494 1.328zm-1.494 0l4 4.5 1.494-1.328-4-4.5L.253 6.164z"></path>
    </svg>
  );
};

export const IconWarning = (props: any) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="#FFBC11"
        d="M20.913 4.405c1.16-1.975 4.014-1.975 5.174 0L46.394 38.98c1.174 2-.268 4.519-2.587 4.519H3.193c-2.319 0-3.761-2.52-2.587-4.52L20.913 4.406z"
      ></path>
      <path
        stroke="#000"
        strokeOpacity="0.05"
        d="M25.656 4.658l20.306 34.576C46.942 40.9 45.74 43 43.807 43H3.193c-1.932 0-3.134-2.1-2.155-3.766L21.344 4.658c.967-1.645 3.345-1.645 4.312 0z"
      ></path>
      <circle cx="23.5" cy="36.5" r="2.5" fill="#591515"></circle>
      <path
        fill="#591515"
        d="M25.844 16.495l-.75 12.008a1.597 1.597 0 01-3.188 0l-.75-12.008a2.349 2.349 0 114.688 0z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H48V48H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const IconBulb = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="url(#paint0_radial)"
      d="M30 34.52v.98H18v-.98c0-3.878-1.423-7.621-4-10.52l-.431-.485A14.219 14.219 0 0110 14.093C10 6.36 16.268 0 24 0s14 6.36 14 14.093c0 3.46-1.27 6.836-3.569 9.422L34 24a15.836 15.836 0 00-4 10.52z"
    ></path>
    <path
      stroke="#FF6A00"
      strokeOpacity="0.05"
      d="M29.5 34.52V35h-11v-.48c0-4-1.468-7.862-4.126-10.852l-.432-.485a13.72 13.72 0 01-3.442-9.09C10.5 6.633 16.548.5 24 .5s13.5 6.133 13.5 13.593a13.72 13.72 0 01-3.442 9.09l-.432.485A16.336 16.336 0 0029.5 34.52z"
    ></path>
    <path
      fill="#9B9B9B"
      d="M18 41.844V34.5l2.678.223c2.21.184 4.433.184 6.644 0L30 34.5v7.344A3.156 3.156 0 0126.844 45c-.527 0-1.02.264-1.312.703l-.058.086a1.772 1.772 0 01-2.948 0l-.058-.086A1.578 1.578 0 0021.156 45 3.156 3.156 0 0118 41.844z"
    ></path>
    <path
      fill="url(#paint1_linear)"
      d="M18 41.844V34.5l2.678.223c2.21.184 4.433.184 6.644 0L30 34.5v7.344A3.156 3.156 0 0126.844 45c-.527 0-1.02.264-1.312.703l-.058.086a1.772 1.772 0 01-2.948 0l-.058-.086A1.578 1.578 0 0021.156 45 3.156 3.156 0 0118 41.844z"
    ></path>
    <path
      fill="url(#paint2_linear)"
      d="M18 41.844V34.5l2.678.223c2.21.184 4.433.184 6.644 0L30 34.5v7.344A3.156 3.156 0 0126.844 45c-.527 0-1.02.264-1.312.703l-.058.086a1.772 1.772 0 01-2.948 0l-.058-.086A1.578 1.578 0 0021.156 45 3.156 3.156 0 0118 41.844z"
    ></path>
    <path
      stroke="#000"
      strokeOpacity="0.2"
      d="M18.5 41.844v-6.8l2.137.178c2.238.186 4.488.186 6.726 0l2.137-.179v6.801a2.656 2.656 0 01-2.656 2.656c-.694 0-1.343.347-1.728.925l-.058.087a1.272 1.272 0 01-2.116 0l-.058-.087a2.078 2.078 0 00-1.728-.925 2.656 2.656 0 01-2.656-2.656z"
    ></path>
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(0 28.5 -22.4789 0 24 7)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFFB8F"></stop>
        <stop offset="1" stopColor="#FFBC11"></stop>
      </radialGradient>
      <linearGradient
        id="paint1_linear"
        x1="18"
        x2="30"
        y1="38.5"
        y2="38.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity="0"></stop>
        <stop offset="0.438" stopColor="#fff" stopOpacity="0.42"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="24"
        x2="24"
        y1="45"
        y2="44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.55"></stop>
        <stop offset="1" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export const IconDots = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
  >
    <circle cx="9.5" cy="24.5" r="3.5" fill="#2D3748"></circle>
    <circle cx="23.5" cy="24.5" r="3.5" fill="#2D3748"></circle>
    <circle cx="37.5" cy="24.5" r="3.5" fill="#2D3748"></circle>
  </svg>
);

export const IconScreenshot = (props: any) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.7432 2C16.7432 1.53977 16.3701 1.16667 15.9098 1.16667C15.4496 1.16667 15.0765 1.53977 15.0765 2L16.7432 2ZM18.4864 5.40992C18.9466 5.40992 19.3197 5.03682 19.3197 4.57658C19.3197 4.11635 18.9466 3.74325 18.4864 3.74325L18.4864 5.40992ZM13.3333 3.74325C12.873 3.74325 12.4999 4.11635 12.4999 4.57658C12.4999 5.03682 12.873 5.40992 13.3333 5.40992L13.3333 3.74325ZM15.0765 7.15316C15.0765 7.6134 15.4496 7.9865 15.9098 7.9865C16.3701 7.9865 16.7432 7.6134 16.7432 7.15316L15.0765 7.15316ZM16.7432 4.57658L16.7432 2L15.0765 2L15.0765 4.57658L16.7432 4.57658ZM15.9098 5.40992L18.4864 5.40992L18.4864 3.74325L15.9098 3.74325L15.9098 5.40992ZM15.9098 3.74325L13.3333 3.74325L13.3333 5.40992L15.9098 5.40992L15.9098 3.74325ZM15.0765 4.57658L15.0765 7.15316L16.7432 7.15316L16.7432 4.57658L15.0765 4.57658Z"
      fill="#718096"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66667 6.99998C1.22464 6.99998 0.800716 7.17557 0.488155 7.48813C0.175595 7.80069 0 8.22462 0 8.66665V15.3333C0 15.7753 0.175595 16.1993 0.488155 16.5118C0.800716 16.8244 1.22464 17 1.66667 17H11.6667C12.1087 17 12.5326 16.8244 12.8452 16.5118C13.1577 16.1993 13.3333 15.7753 13.3333 15.3333V8.66665C13.3333 8.22462 13.1577 7.80069 12.8452 7.48813C12.5326 7.17557 12.1087 6.99998 11.6667 6.99998H10.345C10.124 6.99993 9.91208 6.9121 9.75583 6.75581L8.82166 5.82165C8.50918 5.50906 8.08532 5.33341 7.64333 5.33331H5.69C5.24801 5.33341 4.82415 5.50906 4.51167 5.82165L3.5775 6.75581C3.42126 6.9121 3.20933 6.99993 2.98833 6.99998H1.66667ZM6.66667 14.5C6.99497 14.5 7.32006 14.4353 7.62337 14.3097C7.92669 14.184 8.20229 13.9999 8.43443 13.7677C8.66658 13.5356 8.85073 13.26 8.97636 12.9567C9.102 12.6534 9.16666 12.3283 9.16666 12C9.16666 11.6717 9.102 11.3466 8.97636 11.0433C8.85073 10.74 8.66658 10.4644 8.43443 10.2322C8.20229 10.0001 7.92669 9.81592 7.62337 9.69028C7.32006 9.56464 6.99497 9.49998 6.66667 9.49998C6.00362 9.49998 5.36774 9.76337 4.8989 10.2322C4.43006 10.7011 4.16667 11.3369 4.16667 12C4.16667 12.663 4.43006 13.2989 4.8989 13.7677C5.36774 14.2366 6.00362 14.5 6.66667 14.5Z"
      fill="#718096"
    />
  </svg>
);
